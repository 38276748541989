import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Card, CardGrid, Div, Panel, PanelHeader, SimpleCell, Title } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import libermall from '../../img/libermoll.png';
export const Apps = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsxs(Panel, { id: props.id, children: [_jsx(PanelHeader, { className: "delab-fix-header" }), _jsx(Div, { children: _jsxs(CardGrid, { size: "l", children: [_jsxs(Card, { children: [_jsx(Div, { children: _jsx(Title, { level: "3", children: "Swap" }) }), _jsx(SimpleCell, { expandable: true, before: _jsx(Avatar, { mode: 'app', src: 'https://tegro.finance/assets/images/logotype.svg', size: 42 }), href: "https://tegro.finance/swap", target: '_blank', extraSubtitle: "DEX on The Open Network from tegro.money", children: "Tegro.finance" }), _jsx(SimpleCell, { expandable: true, before: _jsx(Avatar, { mode: 'app', src: 'https://static.tildacdn.com/tild6637-6462-4736-b561-366430613539/Android-chrome-512x5.png', size: 42 }), href: "https://app.ston.fi", target: '_blank', extraSubtitle: "STON.fi \u2014 AMM DEX for the TON blockchain", children: "Ston.fi" })] }), _jsxs(Card, { children: [_jsx(Div, { children: _jsx(Title, { level: "3", children: "NFT" }) }), _jsx(SimpleCell, { expandable: true, before: _jsx(Avatar, { mode: 'app', src: libermall, size: 42 }), href: "https://libermall.com/", target: '_blank', extraSubtitle: "Discover, Collect and sell extraordinary NFTs", children: "Libermall" }), _jsx(SimpleCell, { expandable: true, before: _jsx(Avatar, { mode: 'app', src: 'https://ddejfvww7sqtk.cloudfront.net/production/76eb9aa/_next/static/media/apple-touch-icon.22e2a5b4.png', size: 42 }), href: "https://getgems.io/", target: '_blank', extraSubtitle: "Getgems \u2014 The Open Network Marketplace", children: "Getgems" })] })] }) })] }));
};
