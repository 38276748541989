import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardGrid, CellButton, Div, Panel, PanelHeader, SimpleCell } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const Settings = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const history = useNavigate();
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsxs(Panel, { id: props.id, children: [_jsx(PanelHeader, { className: "delab-fix-header" }), _jsxs(Div, { children: [_jsxs(CardGrid, { size: "l", children: [_jsx(Card, { children: _jsx(CellButton, { centered: true, onClick: () => history('/word'), children: "Save privateKey" }) }), _jsx(Card, { children: _jsx(CellButton, { mode: "danger", centered: true, onClick: () => props.setActiveModal('del_wallet'), children: "Deleted account" }) }), _jsxs(Card, { children: [_jsx(SimpleCell, { expandable: true, href: "https://delab.t.me", target: '_blank', children: "Telegram channel" }), _jsx(SimpleCell, { expandable: true, href: "https://github.com/delab-team", target: '_blank', children: "GitHub" })] })] }), _jsx("br", {}), _jsx("div", { style: { textAlign: 'center' }, children: "v. 1.5" })] })] }));
};
