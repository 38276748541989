import { jsx as _jsx } from "react/jsx-runtime";
import { PanelHeader } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export const HeaderBlock = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [namePage, setNamePage] = React.useState('Wallet');
    const isDesktop = window.innerWidth >= 1000;
    const location = useLocation();
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    useEffect(() => {
        if (location.pathname === '/' || location.pathname === '') {
            setNamePage('Wallet');
        }
        if (location.pathname === '/nft') {
            setNamePage('NFT');
        }
        if (location.pathname === '/apps') {
            setNamePage('Apps');
        }
        if (location.pathname === '/settings') {
            setNamePage('Settings');
        }
    }, [location]);
    return (_jsx(PanelHeader, { separator: false, className: "delab-header-full delab-header", children: _jsx("h3", { children: namePage }) }));
};
