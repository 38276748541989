/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/naming-convention */
import { Address } from 'ton';
import { ALL, TonHttpProvider, bytesToBase64, hexToBytes, sha256_sync } from '@openproduct/web-sdk';
import nacl from 'tweetnacl';
import { mnemonicToKeyPair } from 'tonweb-mnemonic';
const delay = (delayInms) => new Promise(resolve => setTimeout(resolve, delayInms));
function getPlatform() {
    const platform = window.navigator?.userAgentData?.platform
        || window.navigator.platform;
    const userAgent = window.navigator.userAgent;
    const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iphonePlatforms = ['iPhone'];
    const iosPlatforms = ['iPad', 'iPod'];
    let os = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'mac';
    }
    else if (iphonePlatforms.indexOf(platform) !== -1) {
        os = 'iphone';
    }
    else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'ipad';
    }
    else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'windows';
    }
    else if (/Android/.test(userAgent)) {
        os = 'linux';
    }
    else if (/Linux/.test(platform)) {
        os = 'linux';
    }
    return os;
}
const tonConnectSignature = (keyPair, item, origin, wallet) => {
    const timestamp = Math.round(Date.now() / 1000);
    const timestampBuffer = Buffer.allocUnsafe(8);
    timestampBuffer.writeBigInt64LE(BigInt(timestamp));
    const domainBuffer = Buffer.from(new URL(origin).host);
    const domainLengthBuffer = Buffer.allocUnsafe(4);
    domainLengthBuffer.writeInt32LE(domainBuffer.byteLength);
    const address = Address.parse(wallet.address);
    const addressWorkchainBuffer = Buffer.allocUnsafe(4);
    addressWorkchainBuffer.writeInt32BE(address.workChain);
    const addressBuffer = Buffer.concat([addressWorkchainBuffer, address.hash]);
    const messageBuffer = Buffer.concat([
        Buffer.from('ton-proof-item-v2/', 'utf8'),
        addressBuffer,
        domainLengthBuffer,
        domainBuffer,
        timestampBuffer,
        Buffer.from(item.payload)
    ]);
    const bufferToSign = Buffer.concat([
        Buffer.from('ffff', 'hex'),
        Buffer.from('ton-connect', 'utf8'),
        Buffer.from(sha256_sync(messageBuffer))
    ]);
    const signature = nacl.sign.detached(Buffer.from(sha256_sync(bufferToSign)), keyPair.secretKey);
    const result = {
        name: 'ton_proof',
        proof: {
            timestamp,
            domain: {
                lengthBytes: domainBuffer.byteLength,
                value: domainBuffer.toString('utf8') // app domain name (as url part, without encoding)
            },
            signature: bytesToBase64(signature),
            payload: item.payload,
            state_init: wallet.walletStateInit
        }
    };
    return result;
};
export class CustomEventApi {
    constructor(address, publicKey, balance, cb, cb2) {
        this._httpProvider = new TonHttpProvider('https://mainnet.tonhubapi.com/jsonRPC');
        const WalletClass = ALL.v4R2;
        const walletContract = new WalletClass(this._httpProvider, {
            publicKey: hexToBytes(publicKey),
            wc: 0
        });
        walletContract.createStateInit().then((data) => {
            this._walletTonConnect = {
                name: 'ton_addr',
                address: Address.parse(address).toRawString(),
                network: '-239',
                walletStateInit: data.stateInit.toBase64(),
                publicKey
            };
        });
        this._accounts = [address];
        this._wallets = [{
                address,
                publicKey,
                walletVersion: 'v4R2'
            }];
        this._walletTonConnect = {
            name: 'ton_addr',
            address: Address.parse(address).toRawString(),
            network: '-239',
            walletStateInit: '',
            publicKey
        };
        this._balance = balance;
        this._cbSend = cb;
        this._cbConnect = cb2;
        if (typeof window.chrome !== 'undefined') {
            if (window.chrome && chrome.runtime && chrome.runtime.onMessageExternal) {
                chrome.runtime.onMessageExternal.addListener(async (request, sender, sendResponse) => {
                    if (request.type === 'deWallet_api') {
                        const resData = request.message.params;
                        let res;
                        switch (request.message.method) {
                            case 'ton_requestAccounts':
                                sendResponse(CustomEventApi.packData(this._accounts));
                                break;
                            case 'ton_requestWallets':
                                sendResponse(CustomEventApi.packData(this._wallets));
                                break;
                            case 'ton_getBalance':
                                sendResponse(CustomEventApi.packData(this._balance));
                                break;
                            case 'ton_sendTransaction':
                                this._cbSend(request.message.params);
                                res = await this.awaitRes();
                                sendResponse(CustomEventApi.packData(res));
                                break;
                            case 'sendTransaction':
                            case 'tonConnect_sendTransaction':
                                this._cbSend(resData, 1);
                                res = await this.awaitRes();
                                // sendResponse(CustomEventApi.packData(res))
                                sendResponse(CustomEventApi.packDataTonConnect(res, 'sendTransaction', resData.id, res === true ? 'ok' : 'error'));
                                break;
                            case 'connect':
                            case 'tonConnect_connect':
                                // sendResponse(CustomEventApi.packData(this._walletTonConnect))
                                const url = resData.manifestUrl;
                                if (url) {
                                    this._cbConnect(url);
                                    res = await this.awaitRes();
                                }
                                else {
                                    res = true;
                                }
                                const res2 = [
                                    this._walletTonConnect
                                ];
                                if (this._KeyP && resData.items.length > 1 && sender.origin) {
                                    const proof_from_user = resData.items[1];
                                    const per = await mnemonicToKeyPair(this._KeyP.mnemonics);
                                    const proof = tonConnectSignature(per, proof_from_user, sender.origin, this._walletTonConnect);
                                    res2.push(proof);
                                }
                                sendResponse(CustomEventApi.packDataTonConnect(res2, 'connect', '0', res === true ? 'ok' : 'error'));
                                break;
                            case 'tonConnect_disconnect':
                            case 'disconnect':
                                sendResponse(CustomEventApi.packDataTonConnect(undefined, 'disconnect', resData.id ?? '0', 'ok'));
                                break;
                            default:
                                sendResponse({ status: 'error', text: 'method not found' });
                                break;
                        }
                    }
                    else {
                        sendResponse({ status: 'error' });
                    }
                    if (request?.message?.method !== 'ton_sendTransaction' || request?.message?.method !== 'sendTransaction') {
                        const rurrentWindow = await chrome.windows.getCurrent();
                        if (rurrentWindow.id)
                            chrome.windows.remove(rurrentWindow.id);
                    }
                    this.res = undefined;
                });
            }
            else {
                console.log('window.chrome undefined 2');
            }
        }
        else {
            console.log('window.chrome undefined');
        }
    }
    static packData(params, status = 'ok') {
        return { status, type: 'deWallet_api_callback', params };
    }
    static packDataTonConnect(params, event, id, status = 'ok') {
        let _params = params;
        if (status === 'error') {
            if (event === 'sendTransaction') {
                _params = {
                    id,
                    error: {
                        code: 300,
                        message: 'error'
                    }
                };
            }
            if (event === 'disconnect') {
                _params = {
                    id,
                    error: {
                        code: 300,
                        message: 'error'
                    }
                };
            }
            if (event === 'connect') {
                const ev = {
                    event: 'connect_error',
                    id: Number(id),
                    payload: {
                        code: 300,
                        message: 'error'
                    }
                };
                return { status, type: 'deWallet_api_callback', params: ev };
            }
        }
        if (status === 'ok') {
            if (event === 'sendTransaction') {
                _params = {
                    id,
                    result: ''
                };
            }
            if (event === 'disconnect') {
                const ev = {
                    event: 'disconnect',
                    payload: {},
                    id: Number(id)
                };
                return { status, type: 'deWallet_api_callback', params: ev };
            }
            if (event === 'connect') {
                const ev = {
                    event: 'connect',
                    id: Number(id),
                    payload: {
                        items: params,
                        device: this.deviceInfo
                    }
                };
                return { status, type: 'deWallet_api_callback', params: ev };
            }
        }
        return { status, type: 'deWallet_api_callback', params: _params };
    }
    updateBalance(balance) {
        this._balance = balance;
    }
    updateRes(res) {
        this.res = res;
    }
    setKey(key) {
        this._KeyP = key;
    }
    async awaitRes() {
        setTimeout(() => false, 30000);
        while (true) {
            if (this.res === true) {
                this.res = undefined;
                console.log('send true');
                return true;
            }
            if (this.res === false) {
                this.res = undefined;
                console.log('send false');
                return false;
            }
            await delay(200);
        }
    }
}
CustomEventApi.deviceInfo = {
    platform: getPlatform(),
    appName: 'DeWallet',
    appVersion: '1.2',
    maxProtocolVersion: 2,
    features: ['SendTransaction']
};
