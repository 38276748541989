import axios from 'axios';
export class TonApi {
    constructor() {
        this._url = 'https://tonapi.io/v1/';
        this._token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMjI0Iiwic2NvcGUiOiJjbGllbnQifQ.vvtwTq9kO89CNP2635wImtrzshdrAM9AYaIbQNqfJHQ';
    }
    async send(url, data) {
        const res = await axios.get(`${this._url}${url}?${new URLSearchParams(data)}`, { headers: { Authorization: `Bearer ${this._token}` } });
        if (res.data.error) {
            console.error(res.data.result);
            return undefined;
        }
        return res.data;
    }
    async getItems(address) {
        const data = await this.send('nft/getItems', { addresses: address });
        console.log(data);
        return data;
    }
    async getCollection(address) {
        const data = await this.send('nft/getCollection', { account: address });
        console.log(data);
        return data;
    }
    async getCollections(limit = 50, offset = 0) {
        const data = await this.send('nft/getCollections', { limit, offset });
        console.log(data);
        return data;
    }
    async searchItems(address, limit = 50, offset = 0) {
        const data = await this.send('nft/searchItems', { collection: address, limit, offset, include_on_sale: true });
        console.log(data);
        return data;
    }
    async searchItemsFromUser(address, limit = 50, offset = 0) {
        const data = await this.send('nft/searchItems', { owner: address, limit, offset, include_on_sale: true });
        console.log(data);
        return data;
    }
    async searchItemsfull(limit = 50, offset = 0) {
        const data = await this.send('nft/searchItems', { limit, offset });
        console.log(data);
        return data;
    }
    async getInfoUser(address) {
        const data = await this.send('account/getInfo', { account: address });
        console.log(data);
        return data;
    }
    async getBalances(address) {
        const data = await this.send('jetton/getBalances', { account: address });
        console.log(data);
        return data;
    }
    async getTransactions(address) {
        const data = await this.send('blockchain/getTransactions', { account: address });
        console.log(data);
        return data;
    }
}
