import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Card, CardGrid, CellButton, Div, FormItem, Header, Input, Panel, PanelHeader } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { VldBuilder, vlds } from 'validatorus-react';
import copy from 'copy-to-clipboard';
import { mnemonicValidate } from 'ton-crypto';
import { CustomWallet } from '../../logic/wallet';
export const Word = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [position, setPosition] = React.useState(0);
    const [word24, setWord24] = React.useState(undefined);
    const history = useNavigate();
    const customW = new CustomWallet();
    const psw = new VldBuilder()
        .with(vlds.VLen, 8, 160)
        .withFname('Password');
    async function viewWord24() {
        const data = customW.loadHashSaveData();
        const privateKey = CustomWallet.HashToKey(psw.value, data.iv, data.hash);
        if (!privateKey) {
            props.consoleLog('#4003 incorrect password');
            return;
        }
        const isMnem = await mnemonicValidate(privateKey.split(' '));
        if (!isMnem) {
            props.consoleLog('#4003 error mnemonic');
            return;
        }
        setWord24(privateKey.split(' '));
        setPosition(1);
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
        }
    }, []);
    return (_jsxs(Panel, { id: props.id, children: [_jsx(PanelHeader, { className: "delab-fix-header" }), position === 1
                ? _jsxs(Div, { children: [_jsx(Header, { style: { textAlign: 'center' }, children: "Save 24 word" }), word24
                            ? _jsx(CardGrid, { size: "m", children: word24.map((word, key) => (_jsx(Card, { children: _jsx(CellButton, { centered: true, onClick: () => {
                                            copy(word24.join(' '));
                                            props.consoleLog('Copyed', true);
                                        }, children: word }) }, key))) })
                            : null, _jsx(Button, { style: { marginTop: '22px' }, stretched: true, size: 'l', onClick: () => history('/settings'), children: "Cancel" })] }) : null, position === 0
                ? _jsxs(Div, { children: [_jsx(Header, { style: { textAlign: 'center' }, children: "Save 24 word" }), _jsx(FormItem, { top: "Password", bottom: psw.error, children: _jsx(Input, { value: psw.value, onChange: e => psw.change(e.target.value), status: psw.iserr, type: "password" }) }), _jsx(FormItem, { children: _jsx(Button, { style: { marginTop: '22px' }, stretched: true, size: 'l', mode: 'tertiary', onClick: () => history('/settings'), children: "Cancel" }) }), _jsx(FormItem, { children: _jsx(Button, { stretched: true, size: 'l', disabled: psw.iserr !== 'valid', onClick: () => viewWord24(), children: "Start" }) })] }) : null] }));
};
